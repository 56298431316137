(function () {

    'use strict';

    const TEETH = require('dental-consultation/json/teeth.json');
    const HISTORIC_DIALOG = require('../dialogs/tooth-historic-dialog');
    const PLAN_PROCEDURE_STEPS_DIALOG = require('../dialogs/plan-procedure-steps-dialog');
    const UPLOAD_DIALOG = require('stand-alone/file-manager/dialogs/dental-file-upload-dialog');
    const VISUALIZE_FILES_DIALOG = require('stand-alone/file-manager/dialogs/visualize-file-dialog');

    const generateId = require('shared/utils/unique-id');

    const DENTAL_ADULT = require('../svg/dental-adult-compiled.svg');
    const DENTAL_CHILD = require('../svg/dental-child-compiled.svg');
    const DENTAL_6_YEARS = require('../svg/dental-6-years-compiled.svg');
    const DENTAL_7_YEARS_AND_HALF = require('../svg/dental-7-and-half-compiled.svg');
    const DENTAL_12_YEARS = require('../svg/dental-12-years-compiled.svg');
    const DENTAL_13_YEARS_AND_HALF = require('../svg/dental-13-and-half-compiled.svg');

    const DENTAL_MOLAR = require('../svg/molar-compiled.svg');
    const DENTAL_INCISOR = require('../svg/incisor-compiled.svg');

    class DentalUtilsService {
        constructor(system, $mdDialog, $translate, moment, $q, $http) {
            this.moment = moment;
            this.$mdDialog = $mdDialog;
            this.$translate = $translate;

            this.$q = $q;
            this.$http = $http;

            this.worker = new Worker(new URL('../workers/dental-chart.worker.js', import.meta.url));// new Worker();
            this.worker.onmessage = ev => this.handleWorkerMessages(ev);

            this.promises = {};
            this.dateFormat = system['date_format'].js;
        }

        static get $inject() {
            return ['system', '$mdDialog', '$translate', 'moment', '$q', '$http'];
        }

        handleWorkerMessages(ev) {
            let promise = _.get(this.promises, ev.data.id);

            if (promise) {
                _.unset(this.promises, ev.data.id)
                promise.resolve(ev.data.styleData);
            }
        }

        proceduresApplied(currentTab, chartID, condition, consultations, preview) {
            let id = generateId();
            this.promises[id] = this.$q.defer();

            this.worker.postMessage({
                id, kwargs: [currentTab, chartID, this.dateFormat, condition, consultations, preview]
            });

            return this.promises[id].promise;
        }

        reducedConditions(condition, values) {
            return _.chain(condition)
                .get('teeth_conditions', [])
                .map(item => _.assign({}, _.pick(item, values), {date: item.applied_at}));
        }

        reducedConsultations(consultations, values) {
            return _.chain(consultations).reduce(handleReduce, []);

            function handleReduce(sum, item) {
                return _.chain(item['teeth_procedures'])
                    .map(procedure => _.assign({}, _.pick(procedure, values), {date: item.visit_date}))
                    .concat(sum)
                    .value();
            }
        }

        openUploadDialog(patient, consultations, plans, context, ev) {
            this.$mdDialog.show(_.assign({}, UPLOAD_DIALOG, {
                targetEvent: ev,
                locals: {
                    patient,
                    teeth: TEETH,
                    context: context,
                    visits: _.map(consultations, item => _.pick(item, ['id', 'visit_date'])),
                    treatmentPlans: _.map(plans, item => _.pick(item, ['id', 'title'])),
                }
            }));
        }

        toothHistoric(patient, condition, consultations, tooth, ev) {
            let fields = [
                'applied_by_name', 'code', 'name', 'teeth', 'treatment_plan_title', 'comment', 'steps', 'step_comments'
            ];

            let conditionMapFunc = itemMap({type: 'dental_module_condition'});
            let consultationMapFunc = itemMap({type: 'dental_module_consultation'});

            let conditionItems = this.reducedConditions(condition, fields).filter(toothFilter).map(conditionMapFunc).value();
            let consultationItems = this.reducedConsultations(consultations, fields).filter(toothFilter).map(consultationMapFunc).value();

            this.$mdDialog.show(_.assign({}, HISTORIC_DIALOG, {
                targetEvent: ev,
                locals: {
                    tooth: tooth,
                    patient: patient,
                    doneProcedures: _.concat(conditionItems, consultationItems)
                }
            }));

            function itemMap(assign) {
                return item => _.assign(item, assign);
            }

            function toothFilter(item) {
                return _.includes(item.teeth, tooth);
            }
        }

        handleFilters(consultations, plans) {
            return [
                {
                    multiple: true,
                    icon: 'mdi-tooth',
                    title: 'dental_module_teeth',
                    contextType: 'teeth',
                    items: _.map(TEETH, i => ({title: i, id: i}))
                },
                {
                    icon: 'mdi-link',
                    multiple: false,
                    title: 'dental_module_visits',
                    contextType: 'dental_consultation',
                    items: _.map(consultations, i => ({
                        id: i.id,
                        title: this.$translate.instant('dental_module_visit_of', i)
                    }))
                },
                {
                    multiple: false,
                    icon: 'mdi-paperclip',
                    title: 'dental_module_treatment_plan',
                    contextType: 'treatment_plan',
                    items: _.map(plans, i => _.pick(i, ['id', 'title']))
                }
            ];
        }

        visualisePanoramics(files, index, ev, locals = {}) {
            const dialog = _.assign({}, VISUALIZE_FILES_DIALOG, {
                targetEvent: ev,
                locals: _.assign({
                    files: files,
                    fileIndex: index,
                    allowEdit: true,
                    hasCompare: false,
                    showPreview: false
                }, locals)
            });

            return this.$mdDialog.show(dialog);
        }

        planProcedureSteps(procedure, ev, readOnly = false) {
            const dialog = _.assign({}, PLAN_PROCEDURE_STEPS_DIALOG, {
                targetEvent: ev,
                locals: {
                    readOnly, procedure: _.cloneDeep(procedure)
                }
            });

            return this.$mdDialog.show(dialog);
        }


        getSchemaSvg(form) {
            const deferred = this.$q.defer();

            switch (form) {
                case 'child':
                    deferred.resolve(DENTAL_CHILD);
                    break;
                case '6-years':
                    deferred.resolve(DENTAL_6_YEARS);
                    break;
                case '7-and-half':
                    deferred.resolve(DENTAL_7_YEARS_AND_HALF);
                    break;
                case '12-years':
                    deferred.resolve(DENTAL_12_YEARS);
                    break;
                case '13-and-half':
                    deferred.resolve(DENTAL_13_YEARS_AND_HALF);
                    break;
                case 'adult':
                    deferred.resolve(DENTAL_ADULT);
                    break;
            }

            return deferred.promise;
        }

        getSurfaceSvg(type) {
            const deferred = this.$q.defer();

            if (type === 'molar') deferred.resolve(DENTAL_MOLAR);
            else if (type ==='incisor') deferred.resolve(DENTAL_INCISOR);

            return deferred.promise;
        }
    }

    module.exports = DentalUtilsService;

})();
