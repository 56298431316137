(function () {
    'use strict';
    const {Subject} = require("rxjs");

    class formsService {
        constructor($q, $http, mnWebSocket, $state) {
            this.q = $q;
            this.http = $http;
            this.ws = mnWebSocket;
            this.$state = $state;
            this.newFormSubject = new Subject();
            this.editFormSubject = new Subject();
            this.deleteFormSubject = new Subject();
        }

        static get $inject() {
            return ["$q", "$http", "mnWebSocket", "$state"];
        }

        getForms(pk) {
            const deferred = this.q.defer();
            let url = `/api/patient-form-template/?patient=${pk}`;
            this.http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        getForm(pk) {
            const deferred = this.q.defer();
            let url = `/api/patient-form-template/${pk}/`;
            this.http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        saveForm(f) {
            let deferred = this.q.defer();
            let url = `/api/patient-form-template/${!_.isNil(f.id) ? f.id + "/" : ""}`;
            this.http.post(url, f)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        deleteForm(pk) {
            let deferred = this.q.defer();
            let url = `/api/patient-form-template/${pk}/`;

            this.http.delete(url)
                .then(success.bind(this), deferred.reject);

            function success(response) {
                this.deleteFormSubject.next(pk);

                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        editState(item, reload = true, template = null) {
            let params = {
                form: _.get(item, 'id', null),
                template: template
            }

            this.$state.go("app.visit.forms.detail", params, {
                inherit: true
            }).then(reload ? this.$state.reload : _.noop, _.noop);
        }

        saveFormTemplate(t) {
            let deferred = this.q.defer();
            let url = `/api/form-template/${!_.isNil(t.id) ? t.id + "/" : ""}`;
            this.http.post(url, t)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        getFormsTemplates(pk) {
            let deferred = this.q.defer();
            this.http.get(`/api/form-template/${pk ? pk + "/" : ""}`)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        deleteFormTemplate(pk) {
            let deferred = this.q.defer();
            let url = `/api/form-template/${pk}/`;

            this.http.delete(url)
                .then(success.bind(this), deferred.reject);

            function success(response) {
                this.deleteFormSubject.next(pk);

                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        updateTemplates(ts) {
            this.ws.pub("forms.FormTemplate.reload_templates", {reload:true}, true)

        }
    }

    module.exports = formsService;

})();
