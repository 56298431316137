/**
 * Created by amine on 01/04/2020.
 */
(function () {

    'use strict';

    const DEFAULT_MODEL = `
        <div style="margin-top: 4mm;margin-bottom: 4mm;text-align: center;">
            <span style="font-size: 1.5em">Pratisoft - module des états</span> <br><br>
            187, Boulevard Abdelmoumen, Immeuble Walili, 7ème étage N°31 - 20390 Casablanca Maroc<br>
            <hr style="margin-top: 4mm;margin-bottom: 4mm;">
            <b style="font-size:1.3em">{{title}}</b>
        </div>
    `;

    class PrintCtrl {
        constructor($mdDialog, $translate, $q) {
            this.dialog = $mdDialog;
            this.translate = $translate;
            this.$q = $q;

            this.orientations = [
                {
                    value: "portrait",
                    label: "states_print_orientation_portrait"
                },
                {
                    value: "landscape",
                    label: "states_print_orientation_landscape"
                }
            ];
        }

        static get $inject() {
            return ["$mdDialog", "$translate", "$q"];
        }

        $onInit() {
            this.state = this.state || this.dialog.cancel();
            this.model = this.model || {content: "", pageOrientation: "portrait"};
        }

        cancel() {
            this.dialog.cancel();
        }

        save() {
            this.dialog.hide(this.model);
        }

        defaultModel(ev) {
            const fill = () => {
                this.model.content = DEFAULT_MODEL.trim();
            };

            if (this.model.content.length) {

                let confirm = this.dialog.confirm()
                    .multiple(true)
                    .title(this.translate.instant('states_default_print_non_empty'))
                    .ariaLabel('remove plan confirm')
                    .targetEvent(ev)
                    .ok(this.translate.instant('confirm_ok'))
                    .cancel(this.translate.instant('confirm_cancel'));

                this.dialog.show(confirm)
                    .then(() => fill(), _.noop);
            } else {
                fill();
            }
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: PrintCtrl,
        template: require("../views/print.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true
    };
})();

