(function () {

    'use strict';


    class FormTemplateCtrl {
        constructor() {

        }

        static get $inject() {
            return [];
        }

        $onInit() {
        }
        checkChoice(f, o) {
            return _.includes(f.value, o);
        }

        addChoice(f, o, idx) {
            if (_.includes(f.value, o)) {
                f.value.splice(idx, 1)
            } else {
                f.value = f.value || [];
                f.value.push(o);
            }
        }
    }


    module.exports = {
        template: require("../views/form-template.tpl.html"),
        bindings: {
            "formTemplate": "=template"
        },
        controllerAs: "vm",
        controller: FormTemplateCtrl
    }
})();