(function () {

    'use strict';

    const LOCALIZATION = require('../json/pivot-i18n-fr.json');
    const {customTabs} = require('../utils/custom-toolbar');

    class PivotComponentCtrl {
        constructor(genericStatesService, system, $interpolate) {
            this.genericStatesService = genericStatesService;
            this.interpolate = $interpolate;

            this.dateFormat = system['date_format'].js;
            this.currencySymbol = system['currency_config']['currency_symbol'];
            this.externalCustomizeCells = this.externalCustomizeCells || _.noop;
            this.reportComplete = this.reportComplete || _.noop;
            this.exportFileName = this.exportFileName || "states-export";
            this.stateName = this.stateName || null;
            this.isChart = false;

            this.data = [];
            this.slice = [];
            this.headers = {};
            this.disableStyling = false;
            this.emptyData = true;
            this.$dataSource = null;
            this.printConfig = {};
            this.pivot = null;


            this.config = {
                "dataSource": {
                    "dataSourceType": "json",
                    "data": [],
                },
                localization: LOCALIZATION,
                "slice": {},
                "options": {
                    "grid": {
                        "type": "compact",
                        "title": "",
                        "showFilter": true,
                        "showHeaders": false,
                        "showTotals": true,
                        "showGrandTotals": "on",
                        "showHierarchies": true,
                        "showHierarchyCaptions": true,
                        "showReportFiltersArea": true
                    },
                    "showEmptyData": false,
                    "configuratorActive": false,
                    "configuratorButton": true,
                    "showAggregations": true,
                    "showCalculatedValuesButton": true,
                    "drillThrough": true,
                    "showDrillThroughConfigurator": true,
                    "sorting": "on",
                    "datePattern": "dd/MM/yyyy",
                    "dateTimePattern": "dd/MM/yyyy HH:mm:ss",
                    "saveAllFormats": false,
                    //"showDefaultSlice": true,
                    "defaultHierarchySortName": "asc"
                },
                "formats": [
                    {
                        "name": "",
                        "thousandsSeparator": " ",
                        "decimalSeparator": ",",
                        "decimalPlaces": 2,
                        "maxSymbols": 20,
                        "nullValue": " ",
                        "infinityValue": "-",
                        "divideByZeroValue": "-"
                    },
                    {
                        "name": "currency",
                        "currencySymbol": this.currencySymbol || " MAD",
                        "currencySymbolAlign": "right"
                    },
                    {
                        "name": "integer",
                        "decimalPlaces": 0
                    }
                ],
            };
        }

        static get $inject() {
            return ["genericStatesService", "system", "$interpolate"];
        }

        $onInit() {
            this.incompleteData = this.incompleteData || false;
            this.gridType = this.gridType || "compact";
            _.set(this.config, "options.grid.type", this.gridType);
            this.$dataSource.subscribe(dataSource => this.handleChange(dataSource));

            const subscription = this.genericStatesService.$printConfig.subscribe(data => {
                if (!_.isNil(data)) {
                    this.printConfig = data;
                    if (subscription) subscription.unsubscribe();
                }
            });

        }

        showChart() {
            // if (document.getElementById('pivotComponent')) {
            //     if (document.getElementById('pivotComponent').style.display === 'none') {
            //         document.getElementById('pivotComponent').style.display = 'block';
            //         document.getElementById('chartContainer').style.display = 'none';
            //         this.isChart = false;
            //     } else {
            //         document.getElementById('pivotComponent').style.display = 'none';
            //         document.getElementById('chartContainer').style.display = 'block';
            //         this.isChart = true;
            //     }
            // }
        }

        printHandler() {
            if (!this.stateName) return false;

            const context = {title: (this.exportFileName || "states-export")};
            const header = `<style>*{font-family: sans-serif}</style>${this.interpolate(_.get(this.printConfig, `${this.stateName}.content`, ""))(context)}`;
            const pageOrientation = _.get(this.printConfig, `${this.stateName}.pageOrientation`, "portrait");

            this.pivot.print({pageOrientation, header});
        }

        exportHandler(fileType) {
            this.pivot.exportTo(fileType, {filename: (this.exportFileName || "states-export")}, _.noop)
        }

        handleChange(dataSource) {
            const onlyData = dataSource.onlyData;
            const gridType = dataSource.gridType;

            if (gridType) _.set(this.config, "options.grid.type", gridType);

            this.headers = onlyData ? this.headers : dataSource.headers;
            this.slice = onlyData ? this.slice : _.get(dataSource, 'slice', {});

            this.data = this.headers ? _.concat([this.headers], dataSource.data) : dataSource.data;
            this.disableStyling = _.get(dataSource, 'disableStyling', false);
            this.emptyData = _.isEmpty(dataSource.data);

            this.config = _.assign({}, this.config, {
                slice: this.slice,
                dataSource: {
                    dataSourceType: "json",
                    data: this.data
                }
            });

            if (!this.pivot) {
                this.pivot = new WebDataRocks({
                    container: "#pivotElement",
                    toolbar: true,
                    report: this.config,
                    beforetoolbarcreated: toolbar => {
                        toolbar.getTabs = customTabs(this);
                    },
                    customizeCell: (cellBuilder, cellData) => this.customizeCells(cellBuilder, cellData),
                    reportcomplete: () => this.reportComplete({pivot: this.pivot, config: this.config})
                });
            } else {
                this.pivot.setReport(this.config);
                this.pivot.refresh();
            }

            //this.pivot.updateData(this.baseData);
//            setTimeout(() => this.pivot.clear(), 60000)
        }

        customizeCells(cellBuilder, cellData) {
            if (!this.disableStyling) {
                if (cellData.type !== "header") {
                    if (cellData.isGrandTotalRow) {
                        cellBuilder.addClass("grand-total-row");
                    } else {
                        if (cellData.isGrandTotalColumn) {
                            cellBuilder.addClass("grand-total-column");
                        } else if (cellData.isTotalRow) {
                            if (_.get(cellData, "rows.0.hierarchyUniqueName") === "physician_fullname")
                                cellBuilder.addClass("wdr-physician");
                        }
                    }
                } else {
                    if (cellData.isTotalRow) {
                        if (_.get(cellData, "member.hierarchyName") === "physician_fullname") {
                            cellBuilder.addClass("wdr-physician-header");
                        }
                    }
                    if (_.includes(["patient_fullname", "payee_fullname"], _.get(cellData, "member.hierarchyName"))) {
                        const rowData = _.find(this.data, item => {
                            return (item.patient_fullname === cellData.label || item.payee_fullname === cellData.label) && _.get(item, 'first_visit', false);
                        });

                        if (rowData) cellBuilder.addClass("wdr-new-patient");
                        cellBuilder.addClass("wdr-patient");
                    }
                }
            }

            this.externalCustomizeCells({cellBuilder, cellData});
        }
    }

    module.exports = {
        template: require("../views/pivot-component.tpl.html"),
        bindings: {
            "$dataSource": "<data",
            "stateName": "@",
            //"hasChart": "=?",
            "externalCustomizeCells": "&?customizeCells",
            "reportComplete": "&?reportComplete",
            "incompleteData": "<?",
            "exportFileName": "<?",
            "gridType": "@?",
        },
        controllerAs: "vm",
        controller: PivotComponentCtrl
    }
})();