/**
 * Created by Hp on 09/05/2017.
 */


(function () {
    'use strict';
    const angular = require('angular');
    const GenericStatesCtrl = require('./controllers/GenericStatesCtrl');
    const ActivitiesCtrl = require('./controllers/ActivitiesCtrl');
    const PatientByAgeCtrl = require('./controllers/PatientByAgeCtrl');
    const PatientAffectationCtrl = require('./controllers/PatientAffectationCtrl');
    const AppointmentReportCtrl = require('./controllers/AppointmentReportCtrl');
    const InsuredReportCtrl = require('./controllers/InsuredReportCtrl');
    const PatientStatusCtrl = require('./controllers/PatientStatusCtrl');
    const PathologiesCtrl = require('./controllers/PathologiesCtrl');
    const AgingBalanceCtrl = require('./controllers/AgingBalanceCtrl');
    const RecoveryBalanceCtrl = require('./controllers/RecoveryBalanceCtrl');
    const PregnancyReport = require('./controllers/PregnancyReport');
    const MedicineReport = require('./controllers/MedicineReport');
    const NewPatientsCtrl = require('./controllers/NewPatientsCtrl');
    const StateSetupCtrl = require('./controllers/StateSetupCtrl');
    const CheckChecksCtrl = require('./controllers/CheckChecksCtrl');
    const DueDatesReportCtrl = require('./controllers/DueDatesReportCtrl');

    const genericStatesService = require("./services/genericStatesService");

    const PivotComponent = require("./components/PivotComponent");

    angular
        .module("medinet")
        .component("mnPivotComponent", PivotComponent)
        .service("genericStatesService", genericStatesService)

        .controller('GenericStatesCtrl', GenericStatesCtrl)
        .controller('ActivitiesCtrl', ActivitiesCtrl)
        .controller('PatientByAgeCtrl', PatientByAgeCtrl)
        .controller('PatientAffectationCtrl', PatientAffectationCtrl)
        .controller('AppointmentReportCtrl', AppointmentReportCtrl)
        .controller('InsuredReportCtrl', InsuredReportCtrl)
        .controller('PatientStatesCtrl', PatientStatusCtrl)
        .controller('PathologyCtrl', PathologiesCtrl)
        .controller('AgingBalancesCtrl', AgingBalanceCtrl)
        .controller('RecoveryBalanceCtrl', RecoveryBalanceCtrl)
        .controller('PregnancyReport', PregnancyReport)
        .controller('MedicineReport', MedicineReport)
        .controller('NewPatientsCtrl', NewPatientsCtrl)
        .controller('StateSetupCtrl', StateSetupCtrl)
        .controller('CheckChecksCtrl', CheckChecksCtrl)
        .controller('DueDatesReportCtrl', DueDatesReportCtrl)
})();