/**
 * Created by amine on 21/10/2020.
 */
(function () {

    'use strict';

    const getObjectId = require('shared/utils/get-object-id');

    class MedicalCareCtrl {
        constructor(medicalCareService, configService, paymentService, $mdDialog, $q, $translate, dragulaService, $scope) {
            this.medicalCareService = medicalCareService;
            this.configService = configService;
            this.dialog = $mdDialog;
            this.q = $q;
            this.translate = $translate;
            this.scope = $scope;
            this.paymentService = paymentService;

            this.ngModelController = this.ngModelController || null;

            this.isSearching = false;
            this.showModel = false;
            this.config = {};
            this.selectedRows = [];
            this.selectedCure = null;

            this.filterMenu = (key) => {
                this._filterMenu(key)
            }

        }

        static get $inject() {
            return ["medicalCareService", "configService", "paymentService", "$mdDialog", "$q", "$translate", "dragulaService", "$scope"];
        }

        $onInit() {
            this.type = this.type || 'plan';
            this.onSave = this.onSave || _.noop;
            this.onExit = this.onExit || _.noop;
            this.useLabel = this.useLabel || 'medical_care.generate_plan';
            this.onUse = this.onUse || null;

            this.isProtocol = this.type === 'protocol';
            this.isQuotation = this.type === 'quotation';
            this.isPlan = this.type === 'plan';

            this.ngModelController.$render = () => this.renderCurrentValue();
            this.applyDiscount = this.paymentService.applyDiscount;

            const promises = [
                this.medicalCareService.getProcedureGroups(),
                this.medicalCareService.getProcedures(),
                this.configService.getByHttp('medical_care_config')
            ];

            if (!this.isProtocol) promises.push(this.medicalCareService.getProtocols());

            this.q.all(promises)
                .then(data => {
                    this.config = data[2] || this.medicalCareService.DEFAULT_CONFIG;

                    this.procedureGroups = this.orderList(data[0], 'groups');
                    this.procedures = data[1];

                    this.procedureGroups = _.map(this.procedureGroups, group => {
                        return _.assign(group, {procedures: _.filter(this.procedures, ['group.id', group.id])});
                    });

                    if (!this.isProtocol) this.protocols = this.orderList(data[3], 'protocols');
                });
        }

        get selectedProcedures() {
            return _.filter(this.procedures, ['active', true])
        }

        get gross_total() {
            if (!this.internalValue.financial_status) return 0;

            this.internalValue.financial_status.gross_total = _.reduce(this.internalValue.cures, (somme, cure) => {
                if (cure.to_delete) return somme;
                else return somme + _.chain(cure.cure_procedures)
                    .map(procedure => procedure.qte * (procedure.price - procedure.discount))
                    .sum()
                    .value();
            }, 0);

            return this.internalValue.financial_status.gross_total;
        }

        get total() {
            if (!this.internalValue.financial_status) return 0;

            this.internalValue.financial_status.total = (this.gross_total - this.internalValue.financial_status.global_discount) || 0;

            return this.internalValue.financial_status.total;
        }

        get disabled() {
            return this.internalValue.is_validated || this.readonly;
        }

        get paid_amount() {
            if (!this.internalValue.financial_status) return 0;

            return this.internalValue.financial_status.paid_amount;
        }

        get locked() {
            if (!this.internalValue.financial_status) return false;

            return this.internalValue.financial_status.is_locked;
        }

        renderCurrentValue() {
            if (!this.ngModelController.$viewValue) {
                this.internalValue = {}
                return this.internalValueChanged();
            }

            if (this.ngModelController.$viewValue !== this.internalValue) {
                this.internalValue = this.ngModelController.$viewValue;
            }
        }

        internalValueChanged() {
            this.ngModelController.$setViewValue(this.internalValue);
            this.ngModelController.$commitViewValue();
        }

        toggleModels() {
            this.showModel = !this.showModel;
        }

        chooseProcedure(procedure) {
            if (procedure.disabled) return false;
            procedure.active = !procedure.active;
        }

        sessionsCountChange(cure) {
            _.forEach(cure.cure_procedures, procedure => procedure.qte = cure.sessions_count)
        }

        selectCure(cure) {
            if (this.selectedCure?.id === cure.id) {
                this.unselectCure();
            } else {
                this.selectedCure = cure;
                const selectedProcedures = _.map(cure.cure_procedures, 'care_procedure.id');

                _.forEach(this.procedures, item => {
                    item.active = _.includes(selectedProcedures, item.id);
                    //if (item.active) item.disabled = true;
                });
            }
        }

        unselectCure() {
            this.selectedCure = null;

            _.forEach(this.procedures, item => {
                item.active = false;
            });
        }

        validateCure() {
            if (this.selectedCure) {
                this.selectedCure.cure_procedures = this._handleCareProcedures(this.selectedProcedures, true, this.selectedCure.cure_procedures);
                this.unselectCure();
            } else {
                this.internalValue.cures.push({
                    utid: getObjectId(),
                    title: this.translate.instant("medical_care.default_cure_title", {nb: this.internalValue.cures.length + 1}),
                    sessions_count: 1,
                    cure_procedures: this._handleCareProcedures(this.selectedProcedures, true),
                });
            }
        }

        removeCure(cure, index) {
            if (_.has(cure, "id")) cure.to_delete = true;
            else this.internalValue.cures.splice(index, 1);
        }

        restoreCure(cure) {
            if (_.has(cure, "id")) cure.to_delete = false;
        }

        moveCure(index, forward) {
            this._move(this.internalValue.cures, index, forward, true)
        }

        moveProcedure(cure, index, forward) {
            this._move(cure.cure_procedures, index, forward);
        }

        _move(list, index, forward, setOrder) {
            const temp = list[index];
            const newIndex = forward ? index + 1 : index - 1;

            list[index] = list[newIndex];
            list[newIndex] = temp;

            if (setOrder) {
                _.forEach(list, (item, itemIndex) => {
                    item.order = itemIndex;
                });
            }
        }

        isDisable(cure) {
            return cure.to_delete || this.internalValue.is_validated;
        }

        useProtocol(protocol) {
            if (this.isProtocol) return false;


            this.internalValue.cures = this.medicalCareService.duplicateItem(_.cloneDeep(protocol), true);
        }

        _handleCareProcedures(procedures, unset = false, defaultValues = []) {
            return _.reduce(procedures, (result, procedure) => {
                if (unset) procedure.active = false;

                const cure_procedure = _.find(defaultValues, ['care_procedure.id', procedure.id]);
                if (cure_procedure) {
                    return _.concat(result, cure_procedure);
                } else {
                    procedure = _.cloneDeep(procedure);

                    return _.concat(result, {
                        name: procedure.title,
                        code: procedure.procedure.code,
                        qte: this.selectedCure ? this.selectedCure.sessions_count : 1,
                        comment: "",
                        price: procedure.price,
                        base_price: procedure.price,
                        discount: 0,
                        procedure: procedure.procedure,
                        care_procedure: procedure
                    });
                }
            }, []);
        }

        orderList(list, key) {
            const order = this.config[`${key}_list_order`];

            return this.medicalCareService.orderList(list, order)
        }


        _reduceProcedureGroups(groups, group, text) {
            const label = _.chain(group.title).toLower().deburr().value();
            if (_.includes(label, text))
                groups.push(group);
            else {
                const procedures = _.reduce(group.procedures, (procedures, procedure) => this._reduceProcedures(procedures, procedure, text), []);

                if (procedures.length > 0) {
                    groups.push(_.chain(group).cloneDeep().assign({procedures}).value());
                }
            }

            return groups;
        }

        _reduceProcedures(procedures, procedure, text) {
            const label = _.chain(procedure.title).toLower().deburr().value();
            if (_.includes(label, text)) procedures.push(procedure);

            return procedures;
        }

        _filterMenu(key) {
            if (key.length === 0) {
                this.items = this.procedureGroups;
            } else {
                const text = _.chain(key).toLower().deburr().value();
                this.items = _.reduce(this.procedureGroups, (groups, group) => this._reduceProcedureGroups(groups, group, text), []);
            }
        }

        filteredItem(isSearching) {
            this.isSearching = isSearching;
        }

        _postSave(data, exit) {
            this.internalValue = data;
            this.internalValueChanged();

            if (exit) this.onExit({saved: true});
            else this.onSave({item: data});
        }

        submit(exit = false) {
            if (this.locked && this.type === "plan") {
                if (this.total >= this.paid_amount) return this._submit(exit);
                else {
                    const deferred = this.q.defer();

                    let confirm = this.dialog.alert()
                        .title(this.translate.instant('medical_care.submit_error_alert.title'))
                        .htmlContent(this.translate.instant('medical_care.submit_error_alert.message'))
                        .ariaLabel('erreur')
                        .targetEvent(null)
                        .ok(this.translate.instant('medical_care.submit_error_alert.confirm'));

                    this.dialog.show(confirm)
                        .then(() => {
                            console.log(11);//.then(deferred.resolve, deferred.reject)
                        }, deferred.reject);

                    return this.q.reject();
                }

            } else {
                return this._submit(exit)
            }
        }

        _submit(exit) {
            return this.medicalCareService.saveMedicalCareModel(this.internalValue, this.type)
                .then(data => this._postSave(data, exit), _.noop);
        }

        invalidate() {
            return this.medicalCareService
                .partialSave({
                    id: this.internalValue.id,
                    is_validated: false,
                    validated_at: null
                }, this.type)
                .then(data => this._postSave(data, false), _.noop);
        }

        useClick() {
            const newItem = this.medicalCareService.duplicateItem(_.cloneDeep(this.internalValue));

            if (newItem && this.onUse) this.onUse({newItem});
        }

        cancel() {
            this.onExit({saved: false});
        }

        deleteModel(ev) {
            const confirm = this.dialog.confirm()
                .targetEvent(ev)
                .ariaLabel('remove plan confirm')
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'))
                .title(this.translate.instant('medical_care.remove_confirm'));

            this.dialog.show(confirm)
                .then(() => {
                    this.medicalCareService.deleteModel(this.internalValue, this.type)
                        .then(() => this.onExit({saved: false}));
                }, _.noop);
        }
    }

    module.exports = {
        bindings: {
            type: '@',
            onSave: '&?',
            onExit: '&?',
            useLabel: "@?",
            onUse: "&?",
            readonly: "<?",
        },
        template: require('medical-care/views/medical-care.tpl.html'),
        controllerAs: "vm",
        require: {ngModelController: "ngModel"},
        controller: MedicalCareCtrl
    };

})();




