(function () {
    'use strict';

    const {BehaviorSubject} = require("rxjs");
    const {statesHeaders} = require("../utils/states-headers");

    const PROCEDURES_ACTIVITY_TYPES = [
        {
            value: 0,
            label: "states_procedure_activity_prices"
        },
        {
            value: 1,
            label: "states_procedure_activity_count"
        },
        {
            value: 2,
            label: "states_procedure_activity_both"
        }
    ];

    class PatientStatusCtrl {
        constructor(configService, authService, $state, $stateParams, genericStatesService, system, $translate, $scope, moment) {
            this.configService = configService;
            this.isRoot = authService.isRootProfile();
            this.$state = $state;
            this.genericStatesService = genericStatesService;
            this.system = system;
            this.translate = $translate;
            this.scope = $scope;
            this.moment = moment;
            this.dateFormat = system['date_format'].js;

            this.cycle = $stateParams["cycle"];
            this.hasChart = false;
            this.states = genericStatesService.getSubcategory(`patient_status`);
            this.state = _.get(this.states, '0');
            this.procedureActivityTypes = PROCEDURES_ACTIVITY_TYPES;
            this.procedureActivityType = 0;
            this.maxDate = null;

            this.currentState = genericStatesService.getCurrentState(`patient_status`);

            const patientId = $stateParams.patientId === -1 ? null : $stateParams.patientId;

            this.query = {
                start: $stateParams.start || null,
                end: $stateParams.end || null,
                patient: patientId,
                patientId: patientId
            };

            this.headers = statesHeaders($translate, ['payment', 'procedure', 'encasement', 'general_account'], {dateAsObject: false});

            this.isChart = false;
            this.$dataSource = new BehaviorSubject({
                slice: {},
                data: [],
                headers: {}, is_exempt: {
                    type: "string",
                    caption: this.translate.instant('exempt_visit_label')
                },
                onlyData: false
            });

            this.exemptFlag = `(${this.translate.instant('general_account_exempt')})`;

            //
            // this.patientCallback = (patient) => this._patientChange(patient);
            // this.removePatient = () => this._patientChange(false);
        }

        static get $inject() {
            return ["configService", "authService", "$state", "$stateParams", "genericStatesService", "system", "$translate", "$scope", "moment"];
        }

        $onInit() {
            this.maxDate = this.moment().toDate();

            const subscription = this.genericStatesService.$config.subscribe(data => {
                if (!_.isNil(data)) {
                    if (this.isRoot) {
                        this.states = this.states.map(item => {
                            item.deactivated = _.get(data, item.name, false);
                            return item;
                        });
                    } else {
                        this.states = _.reject(this.states, item => _.get(data, item.name, false));
                        this.state = _.get(this.states, '0');
                    }

                    if (subscription) subscription.unsubscribe();
                }
            });
        }

        handleStatesChange() {
            this.scope.$applyAsync(() => {
                this.procedureActivityType = 0;
                if (this.query.patient) this.getData();
            });
        }

        getSlice() {
            return {
                expands: {
                    expandAll: true
                },
                drills: {
                    drillAll: true
                },
                rows: this.getRows(),
                columns: this.getColumns(),
                measures: this.getMeasures(),
            };
        }

        modifyData(data) {
            switch (this.state.type) {
                case "general_account":
                    return _.reduce(data, (result, item) => {
                        const value_date = moment(item.value_date, "YYYY-MM-DD").format(this.dateFormat);
                        if (item.str_id.includes("v/"))
                            item.value_label = `${this.translate.instant(`visit_at`)}${value_date}`;

                        if (item.str_id.includes("e/"))
                            item.value_label = `${this.translate.instant(`encasement_at`)} ${value_date}`;

                        if (item.is_exempt && this.state.type === "general_account")
                            item.value_label += this.exemptFlag;
                        item.value_type = this.translate.instant(`general_account_${item.value_type}`);

                        return _.concat(result, item);
                    }, []);
                case "payment":
                case "encasement":
                    return this.genericStatesService.activitiesDataModification(this.state.type, data);

                default :
                    return data;
            }
        }

        getData(onlyData = false) {
            this.promise = this.genericStatesService.getPatientState(_.assign({}, this.query, {
                data_source: this.state.name,
                patient: null
            })).then(data => {
                if (onlyData) {
                    this.$dataSource.next({
                        disableStyling: this.state.type === "general_account",
                        onlyData: true,
                        data: this.modifyData(data)
                    });
                } else {
                    this.$dataSource.next({
                        disableStyling: this.state.type === "general_account",
                        slice: this.getSlice(),
                        data: this.modifyData(data),
                        headers: this.headers[this.state.type]
                    });
                }
            });
        }

        queryChanged(onlyData = true) {
            if (_.isNil(this.query.patientId) || this.query.patientId < 0) return false;

            this.$state.transitionTo(this.$state.current.name, this.query, {
                notify: false
            }).then(() => this.getData(onlyData));
        }

        handleFileName() {
            if (this.state) {
                let title = `${this.translate.instant(this.currentState.label)} [${this.translate.instant(this.state.label)}] `;

                const start = this.moment(this.query.start, this.dateFormat);
                const end = this.moment(this.query.end, this.dateFormat);

                const formattedStart = start.isValid() ? start.format(this.genericStatesService.exportDateFormat) : "*";
                const formattedEnd = end.isValid() ? end.format(this.genericStatesService.exportDateFormat) : "*";

                title += `${formattedStart} - ${formattedEnd}`;

                return title
            }
        }

        getRows(skip = false) {
            switch (this.state.type) {
                case "general_account":
                    return [
                        {"uniqueName": "value_date"},
                        {"uniqueName": "value_label"}
                    ];

                case "procedure":
                    return [
                        {"uniqueName": "physician_fullname"},
                        {"uniqueName": "patient_fullname"}
                    ];

                case "payment":
                    return [
                        {"uniqueName": "physician_fullname"},
                        {"uniqueName": "payment_mode"},
                        {"uniqueName": "patient_fullname"},
                        {"uniqueName": "payment_date"},
                        {"uniqueName": "payment_label"}
                    ];

                case "encasement":
                    return [
                        {"uniqueName": "physician_fullname"},
                        {"uniqueName": "payment_mode"},
                        {"uniqueName": "payee_fullname"},
                        {"uniqueName": "label"}
                    ];
            }

        }

        getColumns() {
            switch (this.state.type) {

                case "general_account":
                    return [
                        {
                            "uniqueName": "value_type"
                        },
                        {
                            "uniqueName": "Measures"
                        }
                    ];

                case "procedure":
                    return [
                        {
                            "uniqueName": "procedure_name",
                            "caption": this.translate.instant("states_procedure_name")
                        },
                        {
                            "uniqueName": "Measures"
                        }
                    ];
                case "payment":
                case "encasement":
                    return [
                        {
                            "uniqueName": "Measures"
                        }
                    ];
            }
        }

        getMeasures() {
            switch (this.state.type) {
                case "general_account":
                    return [
                        {
                            "uniqueName": "value",
                            "individual": false,
                            "format": "currency",
                            "aggregation": "sum",
                        }
                    ];
                case "procedure":
                    const quantityCell = {
                        "uniqueName": "procedure_quantity",
                        "aggregation": "sum",
                        "format": "integer",
                        "grandTotalCaption": this.translate.instant("qte")
                    };
                    const priceCell = {
                        "uniqueName": "procedure_total_price",
                        "caption": this.translate.instant("total_price"),
                        "format": "currency",
                        "aggregation": "sum"
                    };
                    switch (this.procedureActivityType) {
                        case 1:
                            return [quantityCell];
                        case 2:
                            return [quantityCell, priceCell];
                        default:
                            return [priceCell]
                    }
                case "payment":
                    return [
                        {
                            "uniqueName": "total_amount",
                            "individual": false,
                            "format": "currency",
                            "aggregation": "sum",
                        },
                        {
                            "uniqueName": "global_discount",
                            "individual": false,
                            "format": "currency",
                            "aggregation": "sum",
                        },
                        {
                            "uniqueName": "total_paid_amount",
                            "individual": false,
                            "format": "currency",
                            "aggregation": "sum",
                        },
                        {
                            "uniqueName": "remaining_amount",
                            "individual": true,
                            "format": "currency",
                            "aggregation": "calculated",
                            "formula": "if(\"is_exempt\", 0, \"remaining_amount\")",
                        },
                        {
                            "uniqueName": "exempt_amount",
                            "caption": this.translate.instant("exempt_amount"),
                            "individual": true,
                            "format": "currency",
                            "aggregation": "calculated",
                            "formula": "if(\"is_exempt\", \"remaining_amount\", 0)",
                        },
                        {
                            "uniqueName": "amount",
                            "individual": false,
                            "aggregation": "sum",
                        }
                    ];
                case "encasement":
                    return [
                        {
                            "uniqueName": "total_amount",
                            "individual": false,
                            "format": "currency",
                            "aggregation": "sum",
                        },
                        {
                            "uniqueName": "consumed_amount",
                            "individual": false,
                            "format": "currency",
                            "aggregation": "sum",
                        },
                        {
                            "uniqueName": "remaining_amount",
                            "individual": false,
                            "format": "currency",
                            "aggregation": "sum",
                        }
                    ];
            }
        }

        customizeCells(cellBuilder, cellData) {
            if (this.state.type === "general_account") this.customizeGeneralAccountCells(cellBuilder, cellData);
            else this.customizeStateCells(cellBuilder, cellData);
        }

        customizeGeneralAccountCells(cellBuilder, cellData) {
            if (cellData.type !== "header") {
                cellBuilder.addClass("wdr-general-account");

                const rowLabel = _.chain(cellData)
                    .get("rows", [])
                    .find(["hierarchyUniqueName", "value_label"])
                    .get("caption", "")
                    .value();

                if (rowLabel.includes(this.exemptFlag)) {
                    cellBuilder.addClass("wdr-exempt-value");
                } else if (cellData.value > 0) {
                    cellBuilder.addClass("wdr-credit-value");
                } else if (cellData.value < 0) {
                    cellBuilder.addClass("wdr-debit-value");
                }
            }
        }

        customizeStateCells(cellBuilder, cellData) {
            if (cellData.type !== "header") {
                const column = _.get(cellData, "measure.uniqueName", "");
                if (cellData.isGrandTotalColumn) {
                    if (this.state.type === "payment") {
                        const detailsColumns = [
                            "total_amount",
                            "total_paid_amount",
                            "remaining_amount",
                            "global_discount",
                            "exempt_amount"
                        ];

                        if (_.includes(detailsColumns, column)) {
                            _.remove(cellBuilder.classes, item => item === "grand-total-column");
                            cellBuilder.addClass("wdr-details")
                        }
                    }

                    if (this.state.type === "encasement") {
                        const column = _.get(cellData, "hierarchy.uniqueName", "");
                        const detailsColumns = [
                            "consumed_amount",
                            "remaining_amount"
                        ];

                        if (_.includes(detailsColumns, column)) {
                            _.remove(cellBuilder.classes, item => item === "grand-total-column");
                            cellBuilder.addClass("wdr-details")
                        }
                    }
                }

                if (this.state.type === "payment" && column === "exempt_amount") {
                    if (cellData.isGrandTotalRow) cellBuilder.addClass("wdr-exempt-total");
                    else cellBuilder.addClass("wdr-exempt-detail");

                }
            }
        }

        onComplete(instance) {
            if (this.state.type === 'payment') instance.collapseData("patient_fullname");
            if (this.state.type === 'encasement') instance.collapseData("payee_fullname");
        }

        patientChanged() {
            if (this.query.patient) {
                this.query.patientId = this.query.patient.id;
                this.queryChanged(false);
            } else {
                this.query.patientId = null;
                this.$dataSource.next({
                    onlyData: true,
                    data: []
                });
            }
        }

        clearDate(field) {
            _.set(this.query, field, null);
            this.queryChanged();
        }
    }

    module.exports = PatientStatusCtrl;
})();