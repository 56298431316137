(function () {

    'use strict';

    const STATES_CONFIG = require('../json/states-config.json');

    class StateSetupCtrl {
        constructor(genericStatesService, configService, $mdDialog) {
            this.genericStatesService = genericStatesService;
            this.configService = configService;
            this.dialog = $mdDialog;

            this.configChange = _.mnDelay(() => this._configChange(), 400);

            this.statesConfig = {};
            this.statesPrintConfig = {};
        }

        static get $inject() {
            return ["genericStatesService", "configService", "$mdDialog"];
        }

        $onInit() {
            const mainStates = _.filter(STATES_CONFIG, item => !_.has(item, "subcategory"));
            const subcategories = _.chain(STATES_CONFIG)
                .filter('subcategory')
                .map(item => {
                    return item.items.map(sub => {
                        sub.parent = item.name;
                        sub.isSubcategory = true;
                        return sub;
                    })
                })
                .flatten()
                .value();

            this.states = mainStates.concat(subcategories);
            const configSubscription = this.genericStatesService.$config.subscribe(data => {
                if (!_.isNil(data)) {
                    this.statesConfig = data;
                    if (configSubscription) configSubscription.unsubscribe();
                }
            });
            const printSubscription = this.genericStatesService.$printConfig.subscribe(data => {
                if (!_.isNil(data)) {
                    this.statesPrintConfig = data;
                    if (printSubscription) printSubscription.unsubscribe();
                }
            });
        }

        _configChange() {
            this.configService.set({"states_config": this.statesConfig})
                .then(() => this.genericStatesService.$config.next(this.statesConfig));
        }

        printConfig(state) {
            const dialog = _.assign({}, require('../dialogs/print-dialog'), {
                locals: {
                    state,
                    model: _.get(this.statesPrintConfig, state.name)
                },
                clickOutsideToClose: false,
            });
            this.dialog.show(dialog)
                .then(data => {
                    this.statesPrintConfig[state.name] = data;

                    this.configService.set({"states_print": this.statesPrintConfig})
                        .then(() => this.genericStatesService.$printConfig.next(this.statesPrintConfig));
                }, _.noop);
        }

        stateName(state) {
            if (state.isSubcategory) return `${state.parent}.${state.name}`;
            else return state.name;
        }
    }

    module.exports = StateSetupCtrl;
})();